import axios from 'axios'

export default {
  namespaced: true,
  state: {
    getStore: [],
    // getStoreDomain: [],
    // getStoreID: [],
    getAllCategories: [],
    getCategories: [],
    getSubCategories: [],
    getArticles: [],
    getAuthor: [],
    getSocial: [],
    getSearch: [],
    dataDefault: {
      categories: '',
      article: '',
    },
  },
  mutations: {},
  getters: {},
  actions: {
    // Store
    async GET_STORE_DOMAIN({ rootState, data, state }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/store?domain=${params.domain}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getStore = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    async GET_STORE_ID({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/store?idHelpdesk=${params.id}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getStore = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    // Categories
    async GET_CATEGORIES({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/categories/${params.idSubCat}?idHelpdesk=${params.idStore}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getCategories = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    async GET_ALL_CATEGORIES({ rootState, state, dispatch }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/categories?idHelpdesk=${params.id}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getAllCategories = data.body
          if (data.body.categories && data.body.categories.length > 0) {
            state.dataDefault.categories = {
              id: data.body.categories[0].id,
              store_id: data.body.categories[0].store_id,
              title: data.body.categories[0].title,
            }
            dispatch('GET_FIRST_ARTICLES', {
              id: data.body.categories[0].id,
              store_id: data.body.categories[0].store_id,
            })
          }
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    async GET_FIRST_ARTICLES({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/category/${params.id}?idHelpdesk=${params.store_id}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          if (data && data.body) {
            if (data.body.subcategories && data.body.subcategories.length > 0) {
              state.dataDefault.article = data.body.subcategories[0].articles[0]
            }
          }
        }
      } catch (err) {
        console.log('No tiene creado artículos')
      }
    },
    async VIEW_CATEGORIES({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: 'POST',
          url: `${rootState.configHelpDesk.url}/api/views/categories?idHelpdesk=${params.idStore}`,
          headers: rootState.configAxios.headers,
          data: {
            idCategory: params.idCategory,
          },
        })
        if (data) {
          dispatch('GET_ALL_CATEGORIES')
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    // SubCategories
    async GET_SUBCATEGORIES({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/subcategories/${params.idSubCat}?idHelpdesk=${params.idStore}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getSubCategories = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    // Articles
    async GET_ARTICLES({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/article/${params.idArt}?idHelpdesk=${params.idStore}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getArticles = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    async VIEW_ARTICLE({ rootState, dispatch }, params) {
      try {
        const { data } = await axios({
          method: 'POST',
          url: `${rootState.configHelpDesk.url}/api/views/articles?idHelpdesk=${params.idStore}`,
          headers: rootState.configAxios.headers,
          data: {
            idArticle: params.idArticle,
          },
        })
        if (data) {
          dispatch('GET_ARTICLES')
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    // Authors
    async GET_AUTHOR({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/authors/${params.idAuthor}?idHelpdesk=${params.idStore}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getAuthor = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    // Social
    async GET_SOCIAL({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/social?idHelpdesk=${params.idStore}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getSocial = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    // Rating
    async SET_RATING({ rootState, state }, params) {
      try {
        const { data } = await axios({
          method: 'POST',
          url: `${rootState.configHelpDesk.url}/api/public/rating/articles`,
          headers: rootState.configAxios.headers,
          data: {
            article: params.article,
            value: params.value,
          },
        })
        if (data) {
          state.getSocial = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    // Search
    async GET_SEARCH_ARTICLE({ rootState, state }, params) {
      // console.log(params)
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/public/articles/search?text=${params.search}&state=1&idHelpdesk=${params.id}`,
          headers: rootState.configAxios.headers,
        })
        if (data) {
          state.getSearch = data.body
          return { success: true, data: data.body }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
    async GET_SETTINGS({ rootState }, IdStore) {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${rootState.configHelpDesk.url}/api/settings?idHelpdesk=${IdStore}`,
          headers: rootState.configAxios.headers,
        })
        if (data && data.body) {
          let obj = {
            header: JSON.parse(
              JSON.stringify(JSON.parse(data.body.setting[0].header))
            ),
            footer: JSON.parse(
              JSON.stringify(JSON.parse(data.body.setting[0].footer))
            ),
            breadcrumb: JSON.parse(
              JSON.stringify(JSON.parse(data.body.setting[0].breadcrumb))
            ),
            list_categories: JSON.parse(
              JSON.stringify(JSON.parse(data.body.setting[0].list_categories))
            ),
            list_subcategories: JSON.parse(
              JSON.stringify(
                JSON.parse(data.body.setting[0].list_subcategories)
              )
            ),
            list_articles: JSON.parse(
              JSON.stringify(JSON.parse(data.body.setting[0].list_articles))
            ),
            id: data.body.setting[0].id,
          }
          rootState.settingByHelpDesk = obj
          return { success: true, data: obj }
        }
      } catch (err) {
        return { success: false, data: err.response }
      }
    },
  },
}
