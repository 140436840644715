export default {
  SET_CURRENTSETTING(state, value) {
    if (value) {
      if (value.component && value.setting) {
        switch (value.component) {
          case 'breadcrumb':
            state.settingByHelpDesk.breadcrumb = value.setting
            break
          case 'footer':
            state.settingByHelpDesk.footer = value.setting
            break
          case 'header':
            state.settingByHelpDesk.header = value.setting
            break
          case 'list_articles':
            state.settingByHelpDesk.list_articles = value.setting
            break
          case 'list_categories':
            state.settingByHelpDesk.list_categories = value.setting
            break
          case 'list_subcategories':
            state.settingByHelpDesk.list_subcategories = value.setting
            break
        }
      }
    }
  },
}
