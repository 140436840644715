import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import VueAnalytics from 'vue-analytics'
import './assets/css/style.css'

import '@/plugins/element-ui'
import '@/plugins/vue-moment'
import '@/plugins/element-tiptap'

Vue.use(VueAnalytics, {
  id: 'UA-92934137-1',
  router,
  autoTracking: {
    pageviewTemplate(route) {
      return {
        page: `ayuda${route.path}`,
        title: document.title,
        location: window.location.href,
      }
    },
  },
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
