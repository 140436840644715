import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // },
  routes: [
    {
      path: '/',
      component: () => import('../src/views/Index.vue'),
      children: [
        {
          path: '/:nameDomain',
          name: 'HelpOptions',
          component: () => import('../src/views/isKomercia.vue'),
        },
        {
          path: '/:nameDomain/HelpCenter',
          name: 'HelpCenter',
          component: () => import('../src/views/HelpCenter.vue'),
        },
        {
          path: '/:nameDomain/categories/:idStore/:nameCategory/:id',
          name: 'Categories',
          component: () => import('../src/views/Categories.vue'),
        },
        {
          path: '/:nameDomain/article/:nameCategory/:idStore/:nameArticle/:id/:idArticle',
          name: 'Articles',
          component: () => import('../src/views/Articles.vue'),
        },
      ],
    },
  ],
})
