import Vue from 'vue'
import Vuex from 'vuex'
// import { db } from "@/store/data.js";
import actions from '@/store/actions'
import state from '@/store/state'
import mutations from '@/store/mutations'
import helpDesks from '@/store/modules/helpDesks'
import videos from '@/store/modules/videos'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  actions,
  mutations,
  modules: {
    helpDesks,
    videos,
  },
})
