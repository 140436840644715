import axios from 'axios'

export default {
  textspaced: true,
  state: {
    listVideos: [
      {
        idArticle: '78',
        text: 'Plantilla de WhatsApp y cómo configurarla',
        videoUrl:
          'https://www.youtube.com/embed/XBX4xLDjrB8?si=6T7WUhxiCH_viB7H',
      },
      {
        idArticle: '88',
        text: 'Etiquetas en tu tienda de Komercia ¿Cómo puedo utilizarlas?',
        videoUrl:
          'https://www.youtube.com/embed/-oS5AZmFhVE?si=18awrBC4S-RoDv8j',
      },
      {
        idArticle: '29',
        text: 'Eliminar producto',
        videoUrl:
          'https://www.youtube.com/embed/1F0H5Mdc4cg?si=639dVfiq6tAxkXOZ',
      },
      {
        idArticle: '28',
        text: 'Editar producto de manera rápida',
        videoUrl:
          'https://www.youtube.com/embed/d8paYZeJM18?si=7k7LYXpPwGXNJ2qX',
      },
      {
        idArticle: '27',
        text: 'Crear nuevo producto',
        videoUrl:
          'https://www.youtube.com/embed/xW0NIWY27s4?si=LgfnkDHNF_dz9dW9',
      },
      {
        idArticle: '25',
        text: 'Crear nueva categoría y subcategoría',
        videoUrl:
          'https://www.youtube.com/embed/oQHX4VL_tR0?si=dws2ldyCQpxl3tfY',
      },
      {
        idArticle: '63',
        text: 'Configurar Mi Perfil',
        videoUrl:
          'https://www.youtube.com/embed/_YHQhNa8l9E?si=F-DBgqgdev2ttkzl',
      },
      {
        idArticle: '110',
        text: '¿Cómo personalizar mi link Komercia?',
        videoUrl:
          'https://www.youtube.com/embed/aJgtdGQJqps?si=2xZPUp4u-dBHz_DI',
      },
      {
        idArticle: '98',
        text: '¿Cómo cambiar y recuperar tu contraseña?',
        videoUrl:
          'https://www.youtube.com/embed/K4_nR3m5hlk?si=v-23MbtpTq1BuIK4',
      },
      {
        idArticle: '104',
        text: '¿Cómo agregar un video de YouTube a un producto?',
        videoUrl:
          'https://www.youtube.com/embed/HzvIbxwBNpQ?si=sD2n-FGPnRolcZDP',
      },
      {
        idArticle: '108',
        text: '¿Cómo agregar un nuevo administrador a mi tienda?',
        videoUrl:
          'https://www.youtube.com/embed/9sVJQm8ZJSM?si=uqyPZ623fLZmWW_n',
      },
      {
        idArticle: '95',
        text: 'Checkout dinámico del catálogo de WhatsApp ¿Cómo utilizarlo?',
        videoUrl:
          'https://www.youtube.com/embed/PPxpZ3Edd9A?si=XfEGcYcpMM8bFToT',
      },
      {
        idArticle: '110',
        text: 'Integra Addi en tu tienda virtual  - Komercia',
        videoUrl:
          'https://www.youtube.com/embed/8X7IYjllbxA?si=Fz0BSpb8wCZwCBHC',
      },
    ],
  },
}
